<template>
  <div>
    <transition
      appear
      enter-active-class="transition ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class=""
      leave-class="opacity-100"
      leave-to-class="opacity-0"
      mode="out-in"
    >
      <div>
        <div class="flex justify-between bg-gray-50 px-6 py-4 rounded-lg shadow-sm">
          <div>
            <div class="flex items-center space-x-2">
              <span class="capitalize">
                {{ activePlan.label }}
              </span>
            </div>
            <div class="text-sm text-gray-500">
              ${{ activePlan.price }} / {{ activePlan.key == 'yearly' ? 'year' : 'month' }}
            </div>
            <div class="mt-2 text-gray-500 text-sm opacity-75">
                Includes a 14 day trial before billing begins
              </div>
          </div>
          <button @click.prevent="setSelectedPlan(null)" type="button" class="text-pink-500 underline">
            Change
          </button>
        </div>
        <CreditCard class="mt-6" :saveCardButton="false" @cardComplete="setCardComplete"/>

        <div class="coupons mt-6">
          <button v-if="!showCouponField" class="text-violet font-bold px-1 py-1 rounded" @click="showCouponField = true">
            Have a coupon code?
          </button>

          <div v-if="showCouponField">
            <div v-if="! coupon">
              <label class="font-medium text-black">Coupon Code</label>
              <div class="mt-2">
                <TextInput
                  placeholder="Enter coupon code..."
                  :errors="couponError ? [couponError] : []"
                  v-model="couponCode"
                  @input="fetchCouponCode"
                />
              </div>
            </div>

            <div
              v-else
              class="flex justify-between  px-6 py-4 rounded-lg shadow-sm"
              :class="coupon.is_valid ? 'bg-gray-50' : 'bg-red-100'"
            >
              <div>
                <div>
                  <h4 class="flex items-center space-x-2">
                    <span>Coupon:</span>
                    <span class="font-bold uppercase">
                      {{ coupon.code }}
                    </span>
                  </h4>
                </div>

                <div v-if="coupon.is_valid && coupon.description.length" class="mt-4 text-sm text-green-500 font-medium">
                  {{ coupon.description }}
                </div>

                <div v-if="! coupon.is_valid" class="mt-4 text-sm text-red-500 font-medium">
                  This coupon is not valid.
                </div>
              </div>
              <button @click.prevent="setCoupon(null)" type="button" class="text-pink-500 underline">
                Remove
              </button>
            </div>
          </div>
        </div>

        <div v-if="paymentSetupError" class="mt-6">
          <p class="text-red-500">There was an error while attempting to start your subscription.</p>
          <p v-if="errorDetails" class="mt-2 text-red-500">{{ errorDetails }}</p>
        </div>

        <div v-if="loading" class="mt-6">
          <p class="text-gray-500">Processing payment method...</p>
        </div>
      </div>
    </transition>

    <subscription-plans-modal v-if="showPlans" />
  </div>
</template>

<script>
  import api from '@/api'
  import { debounce } from 'lodash';
  import SubscriptionPlansModal from '@/components/register/SubscriptionPlansModal'
  import { confirmCardSetup } from 'vue-stripe-elements-plus'
  import TextInput from "@/components/shared/TextInput";
  import CreditCard from '@/components/payment-methods/CreditCard';

  export default {
    name: "ProSubscription",
    components: {
      SubscriptionPlansModal,
      TextInput,
      CreditCard,
    },
    data() {
      return {
        showPlans: true,
        loading: false,
        intent: null,
        showCouponField: false,
        couponCode: '',
        couponError: null,
        cardComplete: false,
        card: null,
        paymentSetupError: false,
        errorDetails: null,
        paymentMethod: null,
        saveCard: true,
        selectedPaymentMethod: null,
      }
    },

    computed: {
      selectedPlan() {
        return this.$store.getters['register/selectedPlan']
      },
      currentStep() {
        return this.$store.getters['register/currentStep']
      },
      availablePlans() {
        return this.$store.getters['register/availablePlans']
      },
      activePlan() {
        if (! this.selectedPlan) {
          return {}
        }

        return this.availablePlans.find(plan => plan.key == this.selectedPlan)
      },
      price() {
        if(this.plan == 'yearly') {
          return process.env.VUE_APP_PRO_YEARLY_PRICE ? process.env.VUE_APP_PRO_YEARLY_PRICE : 997;
        }

        return process.env.VUE_APP_PRO_MONTHLY_PRICE ? process.env.VUE_APP_PRO_MONTHLY_PRICE : 97;
      },
      coupon() {
        return this.$store.getters['register/coupon'];
      },
      user() {
        return this.$store.getters['auth/getUser'];
      }
    },

    watch: {
      selectedPlan(selectedPlan) {
        if (! selectedPlan) {
          return
        }

        if (selectedPlan == 'free') {
          this.next()
          return
        }

        this.showPlans = false
      },
      user(user) {
        if (user.referred_by) {
          this.fetchReferrerCoupons();
        }
      },
    },

    methods: {
      next() {
        this.$router.push({name: 'register-profile-type'})
      },
      setSelectedPlan(plan) {
        this.$store.commit('register/setSelectedPlan', plan)
        this.showPlans = true
      },
      setCoupon(coupon) {
        this.$store.commit('register/setCoupon', coupon);
        this.showCouponField = true;
      },
      async submitPayment() {
        if (this.activePlan.price == 0) {
          this.next()
          return
        }

        if (!this.cardComplete) {
          this.$toast.error('Fill in all payment details')
          return;
        }

        if (this.coupon && !this.coupon.is_valid) {
          this.$toast.error('The coupon is not valid. Please remove it or add a valid coupon.')
          return;
        }

        this.loading = true
        this.paymentSetupError = false
        this.errorDetails = null

        const { setupIntent, error } = await confirmCardSetup(this.intent.client_secret, {
          payment_method: {
            card: this.card
          }
        })

        if (error) {
          this.loading = false
          this.paymentSetupError = true

          if (error.message) {
            this.errorDetails = error.message
          }

          return
        }

        this.selectedPaymentMethod = setupIntent.payment_method

        if (this.saveCard) {
          await this.$store.dispatch('auth/storePaymentMethod', {
            payment_method: this.selectedPaymentMethod,
            save: this.saveCard,
          }).catch(error => {
            if (error.response) {
              this.errorDetails = error.response.data.message
            }
          })
        }

        this.createSubscription()
      },

      createSubscription() {
        api.post('/users/me/subscription', {
          plan: this.activePlan.key,
          payment_method: this.selectedPaymentMethod,
          price: this.price,
          coupon: this.coupon?.code,
        }).then(() => {
          this.next()
        }).catch(error => {
          if (error.response) {
            this.errorDetails = error.response.data.message
          }
          this.generatePaymentIntent()
          this.loading = false
          this.paymentSetupError = true
        })
      },

      generatePaymentIntent() {
        api.get(`/user/payment/intent?usage=off_session`).then(response => {
          this.intent = response.data.intent
        })
      },

      fetchReferrerCoupons() {
        this.$store
          .dispatch('coupons/fetchReferrerCoupons')
          .then(coupons => {
            if (!coupons.length) {
              return;
            }

            this.setCoupon(coupons[0]);
            this.showCouponField = true;
          });
      },

      fetchCouponCode: debounce(function (code) {
        this.couponError = null;

        this.$store
          .dispatch('coupons/fetchCoupon', code)
          .then(coupon => {
            this.setCoupon(coupon);
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              this.couponError = 'Coupon not found.';
            }
          })
      }, 500),

      setCardComplete(data) {
        this.cardComplete = data.complete;
        this.card = data.card;
      },
    },

    mounted() {
      this.$store.commit('register/setCurrentStep', 2)
      this.generatePaymentIntent()

      if (this.user.referred_by) {
        this.fetchReferrerCoupons();
      }

      window.Event.$on('createSubscription', () => {
        this.submitPayment()
      })
    },
  }
</script>
