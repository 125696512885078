<template>
  <div class="modal-container fixed inset-0 pointer-events-none z-50">
    <div class="transition-all transform w-full h-full pointer-events-auto overflow-y-auto"
      role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div class="w-full min-h-screen bg-black flex justify-center relative flex-col px-4 pt-8 pb-12">
        <div class="max-w-xs w-full mx-auto rounded-full overflow-hidden flex bg-black relative z-10">
          <label class="flex-1 py-4 text-center cursor-pointer transition duration-100 ease-in-out" :class="{'bg-purple-50': selectedPaidPlan == 'monthly'}">
            <span class="text-white uppercase font-medium">Monthly</span>
            <input class="hidden" type="radio" name="active_paid_plan" value="monthly" v-model="selectedPaidPlan">
          </label>
          <label class="flex-1 py-4 text-center cursor-pointer transition duration-100 ease-in-out" :class="{'bg-purple-50': selectedPaidPlan == 'yearly'}">
            <span class="text-white uppercase font-medium">Yearly</span>
            <input class="hidden" type="radio" name="active_paid_plan" value="yearly" v-model="selectedPaidPlan">
          </label>
        </div>
        <div class="mt-12 max-w-6xl w-full mx-auto grid grid-cols-1 lg:grid-cols-3 lg:gap-8 row-gap-8 lg:row-gap-0 relative z-10" @click.stop>
          <div class="relative left-col bg-guestio-gray-500 px-8 py-8 sm:px-12 sm:py-12 overflow-hidden rounded-3xl order-2 lg:order-1">
            <div>
              <h3 class="text-white font-bold text-3xl sm:text-4xl">
                Free
              </h3>
              <p class="mt-2 sm:mt-4 text-white opacity-40">
                Try it as long as you like
              </p>
            </div>
            <div class="mt-8">
              <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-2 sm:gap-6">
                <div>
                  <ul class="space-y-2 sm:space-y-4">
                    <li class="flex items-center text-sm text-white">
                      <CheckIcon class="h-6 text-purple-50" />
                      <span class="ml-2">Find and book guests/shows</span>
                    </li>
                    <li class="flex items-center text-sm text-white">
                      <CheckIcon class="h-6 text-purple-50" />
                      <span class="ml-2">Connect via messaging</span>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul class="space-y-2 sm:space-y-4">
                    <li class="flex items-center text-sm text-white">
                      <CheckIcon class="h-6 text-purple-50" />
                      <span class="ml-2">Access guest's press kit</span>
                    </li>
                    <li class="flex items-center text-sm text-white">
                      <CheckIcon class="h-6 text-purple-50" />
                      <span class="ml-2">Track bookings in one, unified place</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="mt-12">
              <button type="button" class="text-white py-4 px-8 rounded-full w-full sm:w-auto border border-white hover:bg-white transition duration-100 ease-in-out hover:bg-opacity-20" @click="setSelectedPlan('free')">
                Sign up for free
              </button>
            </div>
          </div>
          <div class="right-col col-span-2 bg-gradient-to-b from-purple-50 to-pink-500 px-8 py-8 sm:px-12 sm:py-12 overflow-hidden rounded-3xl relative order-1 lg:order-2">
            <div class="flex justify-between flex-col sm:flex-row">
              <div class="order-2 sm:order-1">
                <h3 class="text-white font-bold text-3xl sm:text-4xl">
                  Going Pro
                </h3>
                <p class="mt-2 sm:mt-4 text-white opacity-40">
                  Limitless possibilites
                </p>
              </div>
              <div class="order-1 sm:order-2 mb-1 sm:mb-0">
                <span class="inline-block px-3 py-1 sm:px-8 sm:py-4 rounded-full uppercase text-white bg-white bg-opacity-20 text-xs tracking-widest">
                  Recommended
                </span>
              </div>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-6 mt-8">
              <div>
                <ul class="space-y-3 sm:space-y-4">
                  <li class="flex items-center text-sm text-white">
                    <CheckIcon class="h-6 opacity-75" />
                    <span class="ml-2">All Free Features/Capabilities</span>
                  </li>
                  <li class="flex items-center text-sm text-white">
                    <CheckIcon class="h-6 opacity-75" />
                    <span class="ml-2">Personalized and Optimized Profile</span>
                  </li>
                  <li class="flex items-center text-sm text-white">
                    <CheckIcon class="h-6 opacity-75" />
                    <span class="ml-2">Audio and Video Guest pitches</span>
                  </li>
                  <li class="flex items-center text-sm text-white">
                    <CheckIcon class="h-6 opacity-75" />
                    <span class="ml-2">Unlimited Wishlist</span>
                  </li>
                  <li class="flex items-center text-sm text-white">
                    <CheckIcon class="h-6 opacity-75" />
                    <span class="ml-2">Unlimited MINIVIEWs ™</span>
                  </li>
                </ul>
              </div>
              <div>
                <ul class="space-y-3 sm:space-y-4">
                  <li class="flex items-center text-sm text-white">
                    <CheckIcon class="h-6 opacity-75" />
                    <span class="ml-2">Customizable Upsells</span>
                  </li>
                  <li class="flex items-center text-sm text-white">
                    <CheckIcon class="h-6 opacity-75" />
                    <span class="ml-2">Homepage Feature</span>
                  </li>
                  <li class="flex items-center text-sm text-white">
                    <CheckIcon class="h-6 opacity-75" />
                    <span class="ml-2">Search Analytics for shows</span>
                  </li>
                  <li class="flex items-center text-sm text-white">
                    <CheckIcon class="h-6 opacity-75" />
                    <span class="ml-2">Customizable booking flow</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="mt-12">
              <button type="button" class="bg-purple-50 text-white py-4 sm:px-8 rounded-full w-full sm:w-auto border border-purple-50 hover:bg-purple-700 transition duration-100 ease-in-out" @click="setSelectedPlan(activePaidPlan.key)">
                Choose plan (${{ activePaidPlan.price }} / {{ activePaidPlan.key == 'yearly' ? 'year' : 'month' }})
              </button>
              <div class="pl-1 mt-3 text-white opacity-75 text-sm text-center sm:text-left">
                Includes a 14 day trial before billing begins
              </div>
            </div>
          </div>
        </div>
        <div class="absolute inset-0 z-0 flex items-start overflow-hidden">
          <SubscriptionGradient class="w-full sm:-mt-8 md:-mt-32 lg:-mt-48" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CheckIcon from '@/components/svgs/CheckIcon'
  import SubscriptionGradient from '@/components/svgs/SubscriptionGradient'

  export default {
    name: "SubscriptionPlansModal",
    components: {
      CheckIcon,
      SubscriptionGradient,
    },
    data() {
      return {
        selectedPaidPlan: 'monthly',
      }
    },

    computed: {
      availablePlans() {
        return this.$store.getters['register/availablePlans']
      },
      activePaidPlan() {
        return this.availablePlans.find(plan => plan.key == this.selectedPaidPlan)
      },
      selectedPlan() {
        return this.$store.getters['register/selectedPlan']
      }
    },

    watch: {
      //
    },

    methods: {
      setSelectedPlan(plan) {
        this.$store.commit('register/setSelectedPlan', plan)
      }
    },

    mounted() {
      this.setSelectedPlan(null)
    }
  }
</script>

<style scoped lang="scss">
  .bg-gradient {
    background: linear-gradient(150.94deg, #6203D0 1.31%, #DC3CF6 77.41%);
  }
</style>
